<template>
   <div class="container">
      <div class="traffic-information traffic-info-message" v-if="trafficMessage">
         <div class="row">
            <div class="col-12">
               <div class="incidents-list">
                  <div class="an-info opened">
                     <div class="inner heading d-flex">
                        <div class="h1 route-code">{{ trafficMessage.line.title }}</div>

                        <div class="relation">
                           <div class="h3 departure font-weight-bold">
                              {{ trafficMessage.line.start }} <span class="dot"></span>
                           </div>
                           <div class="h3 arrival font-weight-bold">
                              {{ trafficMessage.line.end }} <span class="dot"></span>
                           </div>
                        </div>

                        <div class="right-actions d-flex">
                           <div class="cursor-pointer" @click="$router.go(-1)">
                              <i class="ab-icon-arrow-left"></i> zur Übersicht
                           </div>
                        </div>
                     </div>
                     <div class="content">
                        <div class="inner">
                           <div class="mb25">
                              <div class="font-weight-bold mb15 text-muted">
                                 {{ __t('affected_section') }}:
                              </div>
                              <div class="route-stops d-flex">
                                 <template
                                    v-if="
                          trafficMessage.from_station &&
                          trafficMessage.to_station
                        "
                                 >
                                    <div class="start font-weight-bold">
                                       {{ trafficMessage.from_station }}
                                       <span class="dot"></span>
                                    </div>
                                    <div class="stop font-weight-bold">
                                       {{ trafficMessage.to_station }}
                                       <span class="dot"></span>
                                    </div>
                                 </template>
                                 <template v-else>
                                    <div class="stop font-weight-bold">
                                       {{ trafficMessage.title }}
                                    </div>
                                 </template>
                              </div>
                           </div>
                           <div class="mb25">
                              <div class="font-weight-bold mb15 text-muted">
                                 {{ __t('period') }}:
                              </div>
                              {{ trafficMessage.from_time }} -
                              {{ trafficMessage.to_time }}
                              <template v-if="trafficMessage.trains">
                                 <template v-if="trafficMessage && trafficMessage.trains && trafficMessage.trains.length > 0">
                                    <div class="font-weight-bold mb15 mt25 text-muted">Betroffene Zugnummern:</div>
                                    <div class="extra-info-content html-content" v-for="train in trafficMessage.trains" :key="train">
                                       {{ train }}
                                    </div>
                                 </template>
                                 <template v-else>
                                    <div v-if="trafficMessage && trafficMessage.trains && trafficMessage.trains.failure && trafficMessage.trains.failure.length > 0" class="font-weight-bold mb15 mt25 text-muted">Betroffene Zugnummern:</div>
                                       <div class="extra-info-content html-content" v-for="train in trafficMessage.trains.failure" :key="train">
                                                {{ train.train }}
                                       </div>
                                    <div v-if="trafficMessage && trafficMessage.trains && trafficMessage.trains.delays && trafficMessage.trains.delays.length > 0" class="font-weight-bold mb15 mt25 text-muted">Verspätung:</div>
                                    <div class="extra-info-content html-content" v-for="train in trafficMessage.trains.delays" :key="train">
                                       {{ train.train }} - {{ train.delay }} min.
                                    </div>
                                 </template>
                              </template>
                           </div>
                           <div class="mb25">
                              <div class="font-weight-bold mb15 text-muted">Hinweis:</div>
                              <div v-html="trafficMessage.content"/>

                              <template v-if="trafficMessage.extra_info">
                                 <div class="font-weight-bold mb15 mt25 text-muted">Extra:</div>
                                 <div class="extra-info-content html-content" v-html="trafficMessage.extra_info"/>
                              </template>

                              <template v-if="trafficMessage.extra_info_checkboxes">
                                 <div class="mt20 special-icons">
                                    <div class="icon_acc_wrap"
                                         v-for="(icon, index) in trafficMessage.extra_info_checkboxes" :key="index"
                                         style="line-height: 20px">

                                       <span class="icon-acc"><i class="h2 font-weight-normal mr-3"
                                                                 :class="'ab-icon-' + icon.replace('_', '-')"></i></span><span
                                       class="icon-acc-text"> {{ textValues[icon] }} </span>
                                    </div>
                                 </div>
                              </template>
                           </div>

                           <div class="mb25 documents-list" v-if="trafficMessage.links.length">
                              <div class="font-weight-bold mb15 text-muted">Weitere Informationen:</div>
                              <p v-for="(link, index) in trafficMessage.links" :key="index">
                                 <a target="_blank" :href="link.url">{{ link.name }}</a>
                              </p>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import {mapState} from "vuex";

   export default {
      name: "TrafficInformationMessage",
      data() {
         return {
            textValues: {
               'bus': 'Busverkehr/SEV',
               'wheelchair': 'Rollstuhlgerecht',
               'no_bike': 'Keine Fahrradmitnahme ',
            }
         };
      },
      computed: {
         ...mapState(["settings", "constructionWork", "trafficNews"]),
         trafficMessage () {
            return this.$route.name === "construction-work-message" ? this.constructionWork.byID : this.trafficNews.byID;
         },
      },
      async created() {
         if (this.$route.name === "construction-work-message") {
            this.$store.commit("constructionWork/setByID", null);
            await this.$store.dispatch("constructionWork/getByID", this.$route.params.id);
         } else {
            this.$store.commit("trafficNews/setByID", null);
            await this.$store.dispatch("trafficNews/getByID", this.$route.params.id);
         }
      },
      beforeDestroy() {
         if (this.$route.name === "construction-work-message") {
            this.$store.commit("constructionWork/setByID", null);
         } else {
            this.$store.commit("trafficNews/setByID", null);
         }
      },
   };
</script>
