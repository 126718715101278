<template>
   <div class="page_section" v-if="pageLoaded">
      <MobileTitle :title="banner.title"/>
      <Banner :content="banner"/>

      <div class="container">
         <div class="breadcrumb_section">
            <ul>
               <li>
                  <router-link :to="{name: 'home', params: {locale: localeRouteParams}}">
                     {{ __t('homepage') }}
                  </router-link>
               </li>
               <li class="active">{{ banner.title }}</li>
            </ul>
         </div>
      </div>

      <TrafficInformationMessage/>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import {config} from "../../config";
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";
   import TrafficInformationMessage from "../_content_elements/TrafficInformationMessage";

   export default {
      name: "TrafficNewsMessage",
      components: {
         Banner,
         Footer,
         MobileTitle,
         TrafficInformationMessage
      },
      data() {
         return {
            pageLoaded: false,
            banner: {
               title: 'Aktuelle Meldungen',
               image: '/abellio_banner.jpg',
               smallBanner: false
            }
         }
      },
      computed: {
         ...mapState([
            'page',
            'region',
            'locales',
            'settings'
         ]),
         localeRouteParams() {
            return (this.locales.currentLocale != this.locales.defaultLocale) ? this.locales.currentLocale : null
         }
      },
      methods: {
         loadSpecificPageByRegion: function () {
            let self = this;
            this.$store.dispatch('page/getByID', config.trafficNewsIds[this.region.currentRegion.id]).then((data) => {
               this.pageLoaded = true;

               data.content_elements.forEach(function (contentElement) {
                  if (contentElement._group == 'banner') {
                     self.banner = contentElement;
                  }

               })
            }, (error) => {
               if (error.response.status === 404) {
                  this.$router.replace({
                     name: '404', params: {
                        locale: this.$route.params.locale ? this.$route.params.locale : null
                     }
                  });
               }
            });
         }
      },
      created() {
         this.loadSpecificPageByRegion()
      },
      beforeDestroy() {
         this.$store.commit('page/setByID', {});
      },
      watch: {
         "region.currentRegion": function () {
            this.loadSpecificPageByRegion()
         }
      }
   }
</script>
